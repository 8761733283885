import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";

export default function MainMenu() {
  function toggleMenu() {
    const menu = document.querySelector(".menulinks");
    menu.classList.toggle("active");
  }

  return (
    <>
      <div id="mainmenu">
        <div className="container mx-auto py-2">
          <button className="menu-toggler" onClick={toggleMenu}>
            <FontAwesomeIcon icon={faBars} />
          </button>
          <ul className="menulinks">
            <li className="link">
              <Mlink to="" text="Sobre mi" id="about-toggler" />
            </li>
            <li className="link">
              <Mlink to="/redes" text="Redes Sociales" />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export function Mlink({ to, text, id = "" }) {
  return (
    <>
      {id ? (
        <button className="mlink" id={id}>
          {text}
        </button>
      ) : (
        <a className="mlink" href={to}>
          {text}
        </a>
      )}
    </>
  );
}
