import "./style.scss";
export default function Error404() {
  return (
    <>
      <div className="contenedor error404">
        <h1>404</h1>
      </div>
    </>
  );
}
