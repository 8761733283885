export default function Footer() {
    var year = new Date().getFullYear();
  return (
    <>
      <footer>
        <p>Lsyk4 © {year} - Todos los derechos reservados</p>
      </footer>
    </>
  );
}
