import "./style.scss";
export default function Lso({ id = "lso" }) {
  return (
    <>
      <div className="lso" id={id}>
        <img
          loading="lazy"
          src="https://firebasestorage.googleapis.com/v0/b/lsyk-4.appspot.com/o/lsyk4%2Flogonotext.png?alt=media&token=be04e6af-58e4-4009-9046-11d5a12c9c53"
          alt=""
        />
        <div className="imgcover"></div>
      </div>
    </>
  );
}
