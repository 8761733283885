import { useEffect } from "react";

import MainMenu from "../../components/MainMenu";
import Footer from "../../components/Footer";
import Lso from "../../components/Lso";
import "./style.scss";

export default function Inicio() {
  useEffect(() => {
    document.querySelector("#about-toggler").addEventListener("click", () => {
      document.querySelector("#about").style.display = "block";
    });

    document.querySelector(".closeAbout").addEventListener("click", () => {
      document.querySelector("#about").style.display = "none";
    });
  }, []);

  return (
    <>
      <MainMenu />
      <div className="container mx-auto" id="mainPage">
        <div className="lso-c">
          <Lso id="lsomain" />
        </div>
        <h1 className="text-center text-9xl">Lsyk4</h1>
      </div>
      <About />
      <Footer />
    </>
  );
}

export function About() {
  return (
    <>
      <div id="about">
        <button className="closeAbout">
          <span>&times;</span>
        </button>
        <h1 className="text-center text-4xl text-blue-800">Sobre mi</h1>
        <div className="info">
          <h1 className="text-blue-400 text-3xl font-bold mb-4">
            Mae Govannen
          </h1>
          <p className="mt-2 text-red-600">
            (Aviso: hay mucho, muchísimo texto).
          </p>
          <p className="mt-4">
            Quisiera comenzar escribiendo un poco sobre mí, sobre mis
            habilidades, experiencia y proyectos. Me hace ilusión compartir con
            ustedes mi viaje a través de la vida y las cosas que he aprendido a
            lo largo del camino. Quisiera compartir de todo un poco con todos y
            espero que encuentren algo que sea de su agrado. Siempre pueden
            seguirme en mis redes sociales y unirse al servidor de{" "}
            <a href="https://sl.lsyk4.com/lsyk4dc">Discord</a> de la comunidad.
          </p>
          <p className="mt-4">
            Músico, juglar, informático, escritor, psicólogo en formación,
            planeswalker, ¿streamer? de vez en cuando, pero subo más contenido a{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/c/lsyk4"
              className="text-blue-500 underline"
            >
              YouTube
            </a>{" "}
            e{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/lsyk4/"
              className="text-blue-500 underline"
            >
              Instagram
            </a>
            .
          </p>
          <p className="mt-4">
            Actualmente trabajo como informático. Soy desarrollador web,
            administrador de servidores, brindo soporte técnico, redes, sé de
            mantenimiento, reparación y armado de PCs.
          </p>
          <p className="mt-4">
            Por otra parte, estoy estudiando una licenciatura en psicología
            clínica. Actualmente estoy en el tercer semestre de la carrera. No
            es mucho tiempo, pero creo que he aprendido varias cosas sobre las
            personas y sobre mí mismo.
          </p>
          <p className="mt-4">
            Siempre he dicho que sé un poco de todo y que al mismo tiempo no sé
            nada (No es un intento de sonar filosófico o algo del estilo, en
            realidad es más bien lógico. Si no te especializas en algo, al final
            lo que sabes no es tanto ... o algo así xD).
          </p>
          <p className="mt-4">
            Me gusta la electrónica, pero en ese sentido no tengo nada práctico
            que ofrecer además de un par de cosas sencillas, pero me gustaría
            mejorar en eso.
          </p>
          <p className="mt-4">
            Soy músico, canto, toco la flauta y la batería. Mayormente he hecho
            covers de canciones, aunque he compuesto algunas (que pueden verse
            en{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/c/lsyk4"
              className="text-blue-500 underline"
            >
              YouTube
            </a>{" "}
            o en{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/lsyk4/"
              className="text-blue-500 underline"
            >
              Instagram
            </a>
            ).
          </p>
          <p className="mt-4">
            Me gusta escribir, he escrito un par de historias cortas y así. Ni
            de la música ni de los escritos hay nada publicado, pero puede que
            en un futuro lo haga, quién sabe. Algo de lo que escribo está en{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.reddit.com/r/lsyk4/"
              className="text-blue-500 underline"
            >
              Reddit
            </a>{" "}
            (no esperes gran cosa).
          </p>
          <p className="mt-4">
            De vez en cuando subo vídeos a{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/c/lsyk4"
              className="text-blue-500 underline"
            >
              YouTube
            </a>
            , un proyecto que se podría decir que estoy empezando, vídeos que
            edito yo mismo. La calidad de mis ediciones no es una maravilla,
            pero en serio disfruto haciéndolo.
          </p>
          <p className="mt-4">
            Me gustan los videojuegos, algo de lo que subo a{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/c/lsyk4"
              className="text-blue-500 underline"
            >
              YouTube
            </a>{" "}
            es eso. No esperes a un pro, yo solo juego para pasar un buen rato.
            También tengo un par de servidores propios de Minecraft y de otras
            cosas.
          </p>
          <p className="mt-4">
            Me encanta la mitología. Me gusta lo celta, lo nórdico, lo griego,
            lo egipcio. Creo que en parte es por eso que me encanta Tolkien (mi
            escritor favorito). Quizá no tiene nada que ver con nada, pero me
            encanta el Magic The Gathering, su lore y esas cosas.
          </p>
          <p className="mt-4">
            Y hablando de escritores favoritos, Howard Phillips Lovecraft,
            Arthur Conan Doyle y Edgar Allan Poe ❤️.
          </p>
          <p className="mt-4">
            Respecto a la música que me gusta, creo que no puedo definirme en un
            solo género musical. Me gusta el Metal principalmente, pero de vez
            en cuando me puedes encontrar escuchando alguna otra cosa (Pero
            jamás me encontrarás escuchando reggaetón xd). Te dejo un link a una
            lista de reproducción en{" "}
            <a
              href="https://open.spotify.com/playlist/2MtNiEfZzaTCRUW58jlubb?si=f6dd62fdae2743bd"
              target="_blank"
              rel="noreferrer"
              className="text-blue-500 underline"
            >
              Spotify
            </a>{" "}
            con algunas de las canciones que me gustan y de paso me conoces un
            poco más (la iré actualizando cuando me quede tiempo).
          </p>
          <p className="mt-4">
            Pelis y series, he visto tantas de tantas y la verdad es que
            enumerar las que he visto o las mejores me llevaría mucho texto, con
            lo cual diré que mis favoritas han sido, primeramente y como no, La
            trilogía de "El Señor de los Anillos" y por supuesto Supernatural,
            luego está Stargate, Mr. Robot, Iron Man 1, Starwars (aunque algunas
            de esas no existen para mí, pero no hablaré al respecto), Shrek,
            entre otras, pero no se me olvide mencionar Arcane.
          </p>
          <p className="mt-4">
            En fin, iré actualizando (y mejorando) esta sección con el tiempo,
            pero por ahora vean mis redes sociales o hablemos por{" "}
            <a
              href="https://sl.lsyk4.com/lsyk4dc"
              className="text-blue-500 underline"
            >
              Discord
            </a>
            .
          </p>
        </div>
      </div>
    </>
  );
}
