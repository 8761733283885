import "./assets/styles/global.scss";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Pages
import Inicio from './pages/Inicio';
import Mantenimiento from './pages/Mantenimiento';
import Error404 from './pages/NotFound/';
import Redes from './pages/Redes';

function App() {

  const ISINMAINTENANCE = false;

  return (
    <BrowserRouter>
      <Routes>
        {
          ISINMAINTENANCE ? (
            <Route path='/' element={<Mantenimiento />} />
          ) : (
            <Route path='/' element={<Inicio />} />
          )
        }
        <Route path='/404' element={<Error404 />} />
        <Route path='/redes' element={<Redes />} />
        <Route path='*' element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
