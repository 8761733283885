import Socialrow from "./Socialrow";
// import YoutubeEmbed from "./YoutubeEmbed";
// import TwitchEmbed from "./TwitchEmbed";
import Footer from "./Footer";
import "./redes.scss";

export default function Redes() {
  return (
    <>
      <div className="container" id="socialLinks">
        <a href="/">
          <img
            className="logo"
            src="https://firebasestorage.googleapis.com/v0/b/lsyk-4.appspot.com/o/lsyk4%2FLsyk4Text-small.png?alt=media&token=c32945d4-ba8f-429d-a0dc-c6ba2ba5994d"
            alt=""
          />
        </a>
        <p>
          Planeswalker - Lsyk
          <br />
          Músico, juglar, informático, escritor, psicólogo en compilación,
          ¿streamer?
        </p>
        <div className="row mb-4 mt-4">
          <h1 className="mb-4 text-4xl text-blue-600 font-bold">Mis redes:</h1>
          <Socialrow
            img="https://firebasestorage.googleapis.com/v0/b/lsyk-4.appspot.com/o/lsyk4%2Fsocialicons%2Fdiscord.png?alt=media&token=517e07ff-9d1f-4ca4-9012-f9463254e0c2"
            title="Discord"
            link="https://sl.lsyk4.com/lsyk4dc"
          />
          <Socialrow
            img="https://firebasestorage.googleapis.com/v0/b/lsyk-4.appspot.com/o/lsyk4%2Fsocialicons%2Finstagram.png?alt=media&token=4d158840-003e-4ba1-bd45-7b816d7ab935"
            title="Instagram"
            link="https://instagram.com/lsyk4"
          />
          <Socialrow
            img="https://firebasestorage.googleapis.com/v0/b/lsyk-4.appspot.com/o/lsyk4%2Fsocialicons%2Fyoutube.png?alt=media&token=e9cb204a-e999-4a39-b30c-baacca0f5341"
            title="Youtube"
            link="https://youtube.com/c/lsyk4"
          />
          <Socialrow
            img="https://firebasestorage.googleapis.com/v0/b/lsyk-4.appspot.com/o/lsyk4%2Fsocialicons%2Ftiktok.png?alt=media&token=9a13651e-761a-4db8-914c-1cb3f849ea99"
            title="Tiktok"
            link="https://www.tiktok.com/@lsyk4?lang=es"
          />
          <Socialrow
            img="https://firebasestorage.googleapis.com/v0/b/lsyk-4.appspot.com/o/lsyk4%2Fsocialicons%2Fspotify.png?alt=media&token=1f44a746-18cd-4bfc-9491-9d446214d468"
            title="Spotify Playlist"
            link="https://open.spotify.com/playlist/2MtNiEfZzaTCRUW58jlubb?si=f6dd62fdae2743bd"
          />
          <Socialrow
            img="https://firebasestorage.googleapis.com/v0/b/lsyk-4.appspot.com/o/lsyk4%2Fsocialicons%2Fspotifypodcast.png?alt=media&token=033d8b4c-80b7-4af3-8de5-890c217c2d77"
            title="Spotify Podcast"
            link="https://open.spotify.com/show/2gX8hwdAAX0aOxgADryuyv?si=8d346fc569964ac1"
          />
          <Socialrow
            img="https://firebasestorage.googleapis.com/v0/b/lsyk-4.appspot.com/o/lsyk4%2Fsocialicons%2Fweb.png?alt=media&token=05a4fac2-5a9b-4e1b-a59c-c809471b57bd"
            title="Web"
            link="https://lsyk4.com/"
          />
          <Socialrow
            img="https://firebasestorage.googleapis.com/v0/b/lsyk-4.appspot.com/o/lsyk4%2Fsocialicons%2Ftwitter.png?alt=media&token=2b9031bc-9d6f-428f-acbb-32ecc3d8bcc4"
            title="Twitter"
            link="https://twitter.com/lsyk4"
          />
          <Socialrow
            img="https://firebasestorage.googleapis.com/v0/b/lsyk-4.appspot.com/o/lsyk4%2Fsocialicons%2Freddit.png?alt=media&token=0fc2aea0-38a3-41f4-b933-74cf3e2928fb"
            title="Reddit"
            link="https://www.reddit.com/r/lsyk4/"
          />
          <Socialrow
            img="https://firebasestorage.googleapis.com/v0/b/lsyk-4.appspot.com/o/lsyk4%2Fsocialicons%2Fgithub.png?alt=media&token=d437c6d5-5d38-4314-b1cf-0c2a0e3c9503"
            title="Github"
            link="https://github.com/lsyk4"
          />
          <Socialrow
            img="https://firebasestorage.googleapis.com/v0/b/lsyk-4.appspot.com/o/lsyk4%2Fsocialicons%2Ftwitch.png?alt=media&token=865e0ef4-826f-4373-831a-c1ac93b9eab8"
            title="Twitch"
            link="https://www.twitch.tv/lsyk4"
          />
        </div>
        <Footer />
      </div>
    </>
  );
}
