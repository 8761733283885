import "./style.scss";
export default function Footer() {
  const { version } = require("../../../package.json");
  return (
    <>
      <div id="mainfooter">
        <div className="container sociallinks flex justify-center">
          <a
            className="sociallink mx-1"
            href="https://www.instagram.com/lsyk4"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/lsyk-4.appspot.com/o/lsyk4%2Fsocialicons%2Finstagram.png?alt=media&token=d055cad8-83c3-4950-a56c-edcd54f7c55b"
              alt=""
            />
          </a>
          <a
            className="sociallink mx-1"
            href="https://www.youtube.com/@lsyk4"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/lsyk-4.appspot.com/o/lsyk4%2Fsocialicons%2Fyoutube.png?alt=media&token=6fb86a6e-5d1f-4b07-a229-b6f085288f6e"
              alt=""
            />
          </a>
          <a
            className="sociallink mx-1"
            href="https://www.github.com/lsyk4"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/lsyk-4.appspot.com/o/lsyk4%2Fsocialicons%2Fgithub.png?alt=media&token=42bf6472-943f-4d31-a55c-3c5fd1c9d62b"
              alt=""
            />
          </a>
        </div>
        <div className="container mt-2 text-center">
          {new Date().getFullYear()} © Lsyk4 (V {version})
        </div>
      </div>
    </>
  );
}
