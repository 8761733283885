import "./socialrow.scss";
export default function Socialrow({ img, title, link }) {
  return (
    <>
      <a
        className="col-12 socialrow"
        href={link}
        target="_blank"
        rel="noreferrer"
      >
        <div className="row">
          <div className="col-3">
            <img src={img} alt="" />
          </div>
          <div className="col-8">
            <span className="text-purple-800">{title}</span>
          </div>
        </div>
      </a>
    </>
  );
}
